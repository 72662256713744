import React, {useCallback, useEffect, useState} from 'react';
import Head from 'next/head';
import {NextPageContext} from 'next';
import * as API from '../../utils/api';
import {useRouter} from 'next/router';
import {useCompany} from '../../contexts/CompanyContext';

import Header from '../../components/Lobby/Header/index';
import {
  Page,
  PageInside,
  Main,
  Side,
  Wall,
} from '../../components/Lobby/layout';
import {BoxesAction, BoxAction} from '../../components/Lobby/Boxes';
import {HeadingL, HeadingM, HeadingS, Text} from '../../components/Typography';
import LiveFlow from '../../components/Lobby/LiveFlow';
import ReservationFlow from '../../components/Lobby/ReservationFlow';
import EndFlow from '../../components/Lobby/EndFlow';
import EndWebinarFlow from '../../components/Lobby/WebinarJoinFlow/EndStep';
import DirectAccessFlow from '../../components/Lobby/DirectAccessFlow';
import {FlowWrapper} from '../../components/Lobby/Boxes/layout';
import WallComp from '../../components/Wall';
import Legal from '../../components/Legal';
import AdminBar from '../../components/AdminBar';
import TextEditable from '../../components/TextEditable';
import {useTranslation} from 'react-i18next';
import StoreNotAvailable from '../../components/StoreNotAvailable';

type PageProps = {slug: string; store: Store};

const FLOW_MAP = {
  live: LiveFlow,
  reservation: ReservationFlow,
  accedi: DirectAccessFlow,
  end: EndFlow,
  endWebinar: EndWebinarFlow,
};

export default function StorePage({slug, store}: PageProps) {
  const [company] = useCompany();
  const router = useRouter();
  const flow = router.query.flow as LobbyFlow | null;

  const hasFlow = !!flow;

  const flowContainer = React.useRef<HTMLDivElement>();
  const [liveAvailable, setLiveAvailable] = useState(false);

  const isReservationActive = store.reservationActive;

  const changeFlow = useCallback(
    (flow: LobbyFlow) => () => {
      flowContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      router.push(
        {
          pathname: '/[store]',
          query: {
            store: store.slug,
            flow,
          },
        },
        `/${store.slug}/${flow}`,
        {
          shallow: true,
        }
      );
    },
    [store.slug, router]
  );

  const FlowComp = FLOW_MAP[flow] || React.Fragment;

  useEffect(() => {
    store.liveRooms.forEach((r: Room) => {
      if (r.id === store.rooms[0].id) {
        setLiveAvailable(true);
      }
    });
  }, [store]);

  const {t} = useTranslation();

  const head = (
    <>
      <Head>
        <title>{store.name || 'Youmain'}</title>
        <meta
          property="og:title"
          content={store.name || 'Youmain'}
          key="title"
        />
        <meta
          property="og:description"
          content={`La Stanza ${store.name || 'Youmain'} è a tua disposizione!`}
          key="description"
        />
      </Head>
      <AdminBar store={store} />
    </>
  );

  // subscription not active, show store not available
  if (company.subscriptionStatus === 'inactive') {
    return (
      <>
        {head}
        <StoreNotAvailable store={store} company={company} />
      </>
    );
  }

  // subscription active, show store
  return (
    <>
      {head}

      <Page
        style={{
          backgroundImage: store.background
            ? `url('/_next/image?url=${store.background}&w=1920&q=90')`
            : 'none',
        }}
      >
        <PageInside>
          <Main>
            <Header
              logo={company.logo}
              storeName={store.name}
              storeSlug={store.slug}
              collapse={hasFlow}
            >
              <TextEditable
                roomId={store.rooms[0].id}
                langKey={'welcome-title'}
              >
                <HeadingL>
                  {t('welcome-title', {ns: store.rooms[0].id})}
                </HeadingL>
              </TextEditable>

              <TextEditable roomId={store.rooms[0].id} langKey={'welcome-text'}>
                <Text>{t('welcome-text', {ns: store.rooms[0].id})}</Text>
              </TextEditable>

              {(isReservationActive || liveAvailable) && (
                <>
                  <TextEditable
                    roomId={store.rooms[0].id}
                    langKey={'welcome-subtitle'}
                  >
                    <HeadingM>
                      {t('welcome-subtitle', {ns: store.rooms[0].id})}
                    </HeadingM>
                  </TextEditable>
                  <TextEditable
                    roomId={store.rooms[0].id}
                    langKey={'welcome-subtext'}
                  >
                    <Text>{t('welcome-subtext', {ns: store.rooms[0].id})}</Text>
                  </TextEditable>
                </>
              )}
            </Header>
            <BoxesAction $visible={!hasFlow}>
              {liveAvailable && (
                <BoxAction
                  label={
                    <TextEditable
                      roomId={store.rooms[0].id}
                      langKey={'live-flow-title'}
                    >
                      <HeadingS>
                        {t('live-flow-title', {ns: store.rooms[0].id})}
                      </HeadingS>
                    </TextEditable>
                  }
                  buttonTitle={t('live-action', {ns: store.rooms[0].id})}
                  status={store.rooms[0].isBusy ? 'busy' : 'available'}
                  onClick={changeFlow('live')}
                />
              )}
              {isReservationActive && (
                <BoxAction
                  label={
                    <TextEditable
                      roomId={store.rooms[0].id}
                      langKey={'reservation-flow-title'}
                    >
                      <HeadingS>
                        {t('reservation-flow-title', {ns: store.rooms[0].id})}
                      </HeadingS>
                    </TextEditable>
                  }
                  buttonTitle={t('reservation-action', {ns: store.rooms[0].id})}
                  status={null}
                  onClick={changeFlow('reservation')}
                />
              )}
            </BoxesAction>

            <FlowWrapper $visible={hasFlow} ref={flowContainer}>
              <FlowComp store={hasFlow ? store : null} />
            </FlowWrapper>

            {/* {hasFlow && <Legal />} */}
          </Main>
          <Side>
            <Wall $visible={hasFlow}>
              <WallComp
                wall={store.wall?.blocks}
                layout={store.wall?.layouts}
                store={store}
              />
            </Wall>
          </Side>
          <Legal />
        </PageInside>
      </Page>
    </>
  );
}

StorePage.getInitialProps = async function (
  ctx: NextPageContext
): Promise<PropsWithError<PageProps>> {
  const host = ctx?.req?.headers?.host || window.location.host;
  const slug = ctx.query.store as string;

  try {
    const store = await API.fetchStoreConfig(host, slug);

    return {
      slug,
      store,
    };
  } catch (e) {
    const statusCode = e?.response?.status || 500;

    if (ctx.res) {
      ctx.res.statusCode = statusCode;
    }

    return {
      slug,
      store: null,
      errorCode: statusCode,
    };
  }
};
