import {FORM_ERROR} from 'final-form';

export function handleFinalFormErrors(e: any) {
  // There are form errors
  const formErrors = e?.response?.data?.errors;
  if (formErrors) {
    return formErrors;
  }

  // Check for generic server error message
  const message = e?.response?.data?.message;
  if (message) {
    return {
      [FORM_ERROR]: message,
    };
  }

  // Send back exception error
  console.error(e);
  return {
    [FORM_ERROR]: e.message,
  };
}

export function objectToFormData(data: {}): FormData {
  const fd = new FormData();

  traverseObject(fd, data);

  return fd;
}

function traverseObject(fd: FormData, data: {}, prefix: string = ''): void {
  for (const key in data) {
    const value = data[key];
    const name = prefix ? `${prefix}[${key}]` : key;

    traverseValue(fd, value, name);
  }
}

function traverseArray(fd: FormData, data: Array<{}>, prefix: string): void {
  data.forEach((value, index) => {
    traverseValue(fd, value, `${prefix}[${index}]`);
  });
}

function traverseValue(fd: FormData, value: any, name: string): void {
  if (typeof value === 'string') {
    fd.append(name, value);
    return;
  }

  if (typeof value === 'number') {
    fd.append(name, '' + value);
    return;
  }

  if (typeof value === 'boolean') {
    fd.append(name, value ? '1' : '0');
    return;
  }

  if (value instanceof File) {
    fd.append(`${name}[file]`, value);
    return;
  }

  if (value instanceof Array) {
    traverseArray(fd, value, name);
    return;
  }

  if (typeof value === 'object') {
    traverseObject(fd, value, name);
    return;
  }

  if (typeof value === 'undefined') {
    return;
  }

  console.log({name, value});

  throw new Error('Undefined type');
}
