import React from 'react';
import styled from 'styled-components';
import {HeadingM, HeadingS, Text} from '../../../Typography';
import IconConfirm from './icon-confirm.svg';

const BoxConfirm = () => {
  return (
    <>
      <HeadingM>Prenota un appuntamento</HeadingM>
      <Confirm>
        <img src={IconConfirm.src} alt="Appuntamento confermato" />
        <Title>Appuntamento confermato</Title>
        <Text>
          Riceverai una mail di conferma a breve con il link per partecipare
          all’appuntamento
        </Text>
      </Confirm>
    </>
  );
};

export default BoxConfirm;

const Confirm = styled.div`
  text-align: center;
  padding-top: 30px;
`;

const Title = styled(HeadingS)`
  color: ${(props) => props.theme.colors.success};
`;
