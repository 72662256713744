import {useRouter} from 'next/router';
import React, {useRef, useEffect} from 'react';
import ArgumentStep from './ArgumentStep';
import CalendarStep from './CalendarStep';
import CustomerStep from './CustomerStep';
import ConfirmStep from './ConfirmStep';
import {usePageview} from '../../../utils/analytics';
import useReservationFlow, {FLOW_STEPS} from '../../../hook/useReservationFlow';

// const STEPS_MAP: StepsMap = {
//   [FLOW_STEPS.Argument]: {
//     component: ArgumentStep,
//     nextStep: FLOW_STEPS.Room,
//   },
//   [FLOW_STEPS.Room]: {
//     component: RoomStep,
//     prevStep: FLOW_STEPS.Argument,
//     nextStep: FLOW_STEPS.Calendar,
//   },
//   [FLOW_STEPS.Calendar]: {
//     component: CalendarStep,
//     prevStep: FLOW_STEPS.Argument,
//     nextStep: FLOW_STEPS.Customer,
//   },
//   [FLOW_STEPS.Customer]: {
//     component: CustomerStep,
//     prevStep: FLOW_STEPS.Calendar,
//     nextStep: FLOW_STEPS.Success,
//   },
//   [FLOW_STEPS.Success]: {
//     component: ConfirmStep,
//   },
// };

interface ReservationFlowProps {
  store: Store;
}

export default function ReservationFlow({store}: ReservationFlowProps) {
  const {step, request, topics, ...actions} = useReservationFlow(store);

  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>();

  usePageview(`${router.asPath}/${step}`);

  useEffect(() => {
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [step]);

  useEffect(() => {
    actions.start();
  }, []);

  return (
    <div ref={containerRef}>
      {(function () {
        if (step === FLOW_STEPS.Argument && topics.length > 1) {
          return (
            <ArgumentStep
              store={store}
              topics={topics}
              onNext={actions.setTopic}
              onPrev={() => {
                router.replace(
                  {
                    pathname: '/[store]',
                    query: {
                      store: store.slug,
                    },
                  },
                  `/${store.slug}`,
                  {
                    shallow: true,
                  }
                );
              }}
            />
          );
        }

        if (step === FLOW_STEPS.Calendar) {
          return (
            <CalendarStep
              store={store}
              request={request}
              onNext={actions.setCalendar}
              onPrev={
                topics.length > 1
                  ? actions.backFromCalendar
                  : () => {
                      router.replace(
                        {
                          pathname: '/[store]',
                          query: {
                            store: store.slug,
                          },
                        },
                        `/${store.slug}`,
                        {
                          shallow: true,
                        }
                      );
                    }
              }
            />
          );
        }

        if (step === FLOW_STEPS.Customer) {
          return (
            <CustomerStep
              store={store}
              request={request}
              onNext={actions.setCustomer}
              onPrev={actions.backFromCustomer}
            />
          );
        }

        if (step === FLOW_STEPS.Confirm) {
          return <ConfirmStep store={store} request={request} />;
        }
      })()}
    </div>
  );
}
