import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {useRouter} from 'next/router';
import {Button, ButtonGroup} from '../../Buttons';
import {HeadingM, HeadingXS, Text} from '../../Typography';
import {IconEndRoom} from '../../Icons';
import {Card, Center} from '../Boxes/layout';
import {useUser} from '../../../contexts/UserContext';

interface EndStepProps {
  store: Store;
}

export default function EndStep({store}: EndStepProps) {
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const {user} = useUser();

  const goToHome = () => {
    router.push(
      {
        pathname: '/[store]/',
        query: {
          store: store.slug,
        },
      },
      `/${store.slug}`,
      {
        shallow: true,
      }
    );
  };
  const goToAdmin = () => {
    router.push('/admin');
  };

  return (
    <Card>
      <HeadingM>L’appuntamento è terminato</HeadingM>

      <Center>
        <IconEndRoom fill={theme.colors.success} size={90} />
        <HeadingXS $color={theme.colors.success}>
          Grazie per esserti rivolto a noi.
        </HeadingXS>
        <Text>Puoi abbandonare la pagina.</Text>
      </Center>

      <ButtonGroup asFooter>
        {user && !user.isGuest && (
          <Button
            onClick={() => goToAdmin()}
            title="Pannello Admin"
            secondary
            full
          />
        )}
        <Button title="Torna alla home" full onClick={() => goToHome()} />
      </ButtonGroup>
    </Card>
  );
}
