import {FORM_ERROR} from 'final-form';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {sendReservation} from '../../../utils/api';
import {handleFinalFormErrors} from '../../../utils/forms';
import FormElements from '../../Admin/FormElements';
import {Button, ButtonGroup} from '../../Buttons';
import {Input} from '../../FinalForm';
import {Checkbox} from '../../FormElements/';
import {HeadingM, Subtitle, HeadingS} from '../../Typography';
import {Card} from '../Boxes/layout';
import {useTranslation} from 'react-i18next';
import TextEditable from '../../TextEditable';

interface CustomerStepProps {
  store: Store;
  request: Partial<ReservationRequest>;
  onNext: (data: ReservationRequest) => void;
  onPrev: () => void;
}

export default function CustomerStep({
  store,
  request,
  onNext,
  onPrev,
}: CustomerStepProps) {
  const {t} = useTranslation();

  const handleSubmit = useCallback(
    async (data: ReservationParticipant) => {
      // TODO: validate data
      const {firstName, lastName, email, mobile} = data;

      const reservation: ReservationRequest = {
        ...request,
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: mobile,
        store: store.id,
      };

      try {
        await sendReservation(reservation);

        onNext(reservation);
      } catch (e) {
        let {
          firstName,
          lastName,
          email,
          mobile,
          [FORM_ERROR]: genericError,
          ...otherErrors
        } = handleFinalFormErrors(e);

        genericError = Object.values(otherErrors).concat(genericError || []);
        // TODO: should we send to Sentry generic errors?

        return {
          [FORM_ERROR]: genericError,
          firstName,
          lastName,
          email,
          mobile,
        };
      }
    },
    [onNext, request, store]
  );

  return (
    <Card>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-title'}
      >
        <HeadingM>
          {t('reservation-flow-title', {ns: store.rooms[0].id})}
        </HeadingM>
      </TextEditable>

      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-customer-step-text'}
      >
        <Subtitle>
          {t('reservation-flow-customer-step-text', {ns: store.rooms[0].id})}
        </Subtitle>
      </TextEditable>

      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-customer-step-title'}
      >
        <HeadingS>
          {t('reservation-flow-customer-step-title', {ns: store.rooms[0].id})}
        </HeadingS>
      </TextEditable>

      <Form
        onSubmit={handleSubmit}
        render={({handleSubmit, submitError, submitting}) => (
          <form onSubmit={handleSubmit}>
            <FormElements invisible submitError={submitError}>
              <Input name="firstName" required label="Nome" />
              <Input name="lastName" required label="Cognome" />
              <Input
                name="email"
                required
                type="email"
                label="Indirizzo e-mail"
              />
              <Input name="mobile" required label="Numero di telefono" />
              <Checkbox id="privacy" required>
                Autorizzo il trattamento dei miei dati personali forniti per
                finalità e secondo le modalità specificate nella{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                .
              </Checkbox>
              <ButtonGroup asFooter>
                <Button title="Indietro" secondary auto onClick={onPrev} />
                <Button
                  loading={submitting}
                  type="submit"
                  title="Prenota come ospite"
                  auto
                />
              </ButtonGroup>
            </FormElements>
          </form>
        )}
      />
    </Card>
  );
}
