import React from 'react';
import {Form} from 'react-final-form';
import {useUser} from '../../../contexts/UserContext';
import {Button, ButtonGroup} from '../../Buttons';
import {HeadingM, HeadingXS, Subtitle} from '../../Typography';
import {Card, Center} from '../Boxes/layout';
import FormElements from '../../Admin/FormElements';
import {FormRow, FormCol, Checkbox} from '../../FormElements/';
import {Input} from '../../FinalForm/';

import {FORM_ERROR} from 'final-form';
import {IconHand, IconHeadphones} from '../../Icons';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import TextEditable from '../../TextEditable';

interface CustomerStepProps extends LiveStep {
  store: Store;
  onNext: (name: string) => void | Promise<void>;
  onPrev: () => void | Promise<void>;
}

export default function CustomerStep({
  store,
  onNext,
  onPrev,
}: CustomerStepProps) {
  const {user} = useUser();
  const router = useRouter();
  const directAccess = store.directAccessActive;

  const goToAdmin = () => {
    router.push(`/admin/rooms/${store.rooms[0].id}`);
  };
  const {t} = useTranslation();

  const submitForm = async (data) => {
    try {
      await onNext(data.name);
    } catch (e) {
      const message = e?.response?.data?.message;
      return {
        [FORM_ERROR]: message || 'Si è verificato un errore',
      };
    }
  };

  if (user && user.isGuest === false) {
    return (
      <Card>
        <HeadingM>Accesso alla stanza</HeadingM>

        <CenterStyled>
          <IconHandStyled />
          <HeadingXSStyled>
            Per entrare in una stanza LIVE ti preghiamo di passare dal pannello
            di amministrazione
          </HeadingXSStyled>
        </CenterStyled>

        <ButtonGroup asFooter>
          <Button title="Indietro" secondary full onClick={onPrev} />
          <Button title="Vai al pannello" full onClick={goToAdmin} />
        </ButtonGroup>
      </Card>
    );
  }

  if (!directAccess) {
    return (
      <Card>
        <HeadingM>Accesso alla stanza non disponibile</HeadingM>
        <Center>
          <IconHeadphonesStyled />
          <HeadingXSDecline>
            Siamo spiacenti
            <br />
            al momento l'accesso diretto alla stanza non è disponibile.
          </HeadingXSDecline>
        </Center>
        <ButtonGroup asFooter>
          <Button title="Indietro" secondary full onClick={onPrev} />
        </ButtonGroup>
      </Card>
    );
  }

  return (
    <Card>
      <TextEditable roomId={store.rooms[0].id} langKey={'live-flow-title'}>
        <HeadingM>{t('live-flow-title', {ns: store.rooms[0].id})}</HeadingM>
      </TextEditable>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'live-flow-customer-step-text'}
      >
        <Subtitle>
          {t('live-flow-customer-step-text', {ns: store.rooms[0].id})}
        </Subtitle>
      </TextEditable>

      <Form
        onSubmit={submitForm}
        initialValues={{name: user?.firstName || ''}}
        render={({handleSubmit, submitError, submitting}) => (
          <form onSubmit={handleSubmit}>
            <FormElements invisible submitError={submitError && submitError}>
              <FormRow>
                <FormCol>
                  <Input label="Inserisci il tuo nome" name="name" required />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <Checkbox id="privacy" required>
                    Autorizzo al trattamento dei dati personali forniti per le
                    finalità e secondo le modalità specificate nella{' '}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    .
                  </Checkbox>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <ButtonGroup asFooter>
                    <Button title="Indietro" secondary full onClick={onPrev} />
                    <Button
                      type="submit"
                      title="Avanti"
                      loading={submitting}
                      full
                    />
                  </ButtonGroup>
                </FormCol>
              </FormRow>
            </FormElements>
          </form>
        )}
      />
    </Card>
  );
}

const CenterStyled = styled(Center).attrs((props) => ({
  color: props.theme.colors.warning,
}))``;
const IconHandStyled = styled(IconHand).attrs((props) => ({
  fill: props.theme.colors.warning,
  size: '90px',
}))``;
const HeadingXSStyled = styled(HeadingXS).attrs((props) => ({
  $color: props.theme.colors.warning,
}))``;
const IconHeadphonesStyled = styled(IconHeadphones).attrs((props) => ({
  fill: props.theme.colors.decline,
  size: '90px',
}))``;
const HeadingXSDecline = styled(HeadingXS).attrs((props) => ({
  $color: props.theme.colors.decline,
}))``;
