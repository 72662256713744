import React, {useState} from 'react';
import {Form} from 'react-final-form';
import {Button, ButtonGroup} from '../../Buttons';
import FormElements from '../../Admin/FormElements';
import {HeadingM, Subtitle, Text, TextS} from '../../Typography';
import {Card} from '../Boxes/layout';
import {FormRow, FormCol} from '../../FormElements';
import {Input} from '../../FinalForm';
import * as API from '../../../utils/api';

interface VerifyStepProps extends LiveStep {
  requestId: string;
}
export default function VerifyStep({
  requestId,
  onPrev,
  onNext,
}: VerifyStepProps) {
  const [editMode, setEditMode] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [resended, setResended] = useState(false);
  const [resending, setResending] = useState(false);

  const sendCode = async (data) => {
    setPhoneNumber(data.phoneNumber);

    try {
      await API.sendLiveRequestVerification(requestId, data.phoneNumber);
      setEditMode(false);
    } catch (e) {
      const message = e?.response?.data?.message;
      return {
        phoneNumber: message || 'Invalid form',
      };
    }
  };

  const resendCode = async () => {
    setResending(true);
    setResended(false);

    await API.sendLiveRequestVerification(requestId, phoneNumber);

    setResended(true);
    setResending(false);
  };

  const sendConfirm = async (data) => {
    try {
      await API.verifyLiveRequest(requestId, data.code);

      onNext();
    } catch (e) {
      const message = e?.response?.data?.message;
      return {
        code: message || 'Invalid form',
      };
    }
  };

  return (
    <Card>
      <HeadingM>Verifica identità tramite SMS</HeadingM>

      {editMode && (
        <>
          <Subtitle>
            Per verificare la tua identità abbiamo bisogno di inviarti un SMS
            contenente un codice di verifica di 6 cifre. Per prima cosa,
            inserisci il tuo numero di telefono:
          </Subtitle>
          <Form
            onSubmit={sendCode}
            render={({handleSubmit, submitError, submitting, form}) => (
              <form onSubmit={handleSubmit}>
                <FormElements
                  invisible
                  submitError={submitError && submitError}
                >
                  <FormRow>
                    <FormCol>
                      <Input
                        label="Numero di telefono"
                        name="phoneNumber"
                        type="tel"
                        required
                      />
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol>
                      <ButtonGroup asFooter>
                        <Button
                          title="Indietro"
                          secondary
                          full
                          onClick={onPrev}
                        />
                        <Button
                          type="submit"
                          title="Avanti"
                          loading={submitting}
                          full
                        />
                      </ButtonGroup>
                    </FormCol>
                  </FormRow>
                </FormElements>
              </form>
            )}
          />
        </>
      )}

      {!editMode && (
        <>
          <Text>
            Ti è stato inviato un SMS con un codice di verifica al numero:{' '}
            <strong>{phoneNumber}</strong>.
          </Text>

          <Form
            onSubmit={sendConfirm}
            render={({handleSubmit, submitError, submitting}) => (
              <form onSubmit={handleSubmit}>
                <FormElements
                  invisible
                  submitError={submitError && submitError}
                >
                  <FormRow>
                    <FormCol>
                      <Input label="Codice di verifica" name="code" required />
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol>
                      <Text>
                        Non hai ricevuto il codice?{' '}
                        <Button
                          type="button"
                          title="Invia nuovamente l'SMS"
                          loading={resending}
                          onClick={() => {
                            resendCode();
                          }}
                          inline
                        />
                      </Text>
                      {resended && <TextS>Codice inviato via SMS</TextS>}
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol>
                      <ButtonGroup asFooter>
                        <Button
                          title="Indietro"
                          secondary
                          full
                          onClick={() => setEditMode(true)}
                        />
                        <Button
                          type="submit"
                          title="Conferma"
                          loading={submitting}
                          full
                        />
                      </ButtonGroup>
                    </FormCol>
                  </FormRow>
                </FormElements>
              </form>
            )}
          />
        </>
      )}
    </Card>
  );
}
