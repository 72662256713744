import React, {useEffect, useRef, useState} from 'react';
import Video from 'twilio-video';
import CustomerStep from './CustomerStep';
import {useRouter} from 'next/router';
import WaitingStep from './WaitingStep';
import {usePageview} from '../../../utils/analytics';
import VerifyStep from './VerifyStep';
import BrowserSupport from '../../BrowserSupport';

import useLiveRequest, {FLOW_STEPS} from '../../../hook/useLiveRequestFlow';

type LiveFlowProps = {
  store: Store;
};

export default function LiveFlow({store}: LiveFlowProps) {
  const {step, request, requestId, ...actions} = useLiveRequest(store);

  const containerRef = useRef<HTMLDivElement>();
  const router = useRouter();
  const [isSupported, setIsSupported] = useState(true);

  usePageview(`${router.asPath}/${step}`);

  useEffect(() => {
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [step]);

  useEffect(() => {
    if (Video.isSupported === false) {
      setIsSupported(false);
    }
  }, []);

  if (!isSupported) {
    return <BrowserSupport />;
  }

  return (
    <div ref={containerRef}>
      {(function () {
        if (step === FLOW_STEPS.Customer) {
          return (
            <CustomerStep
              store={store}
              onNext={actions.setName}
              onPrev={() => {
                router.replace(
                  {
                    pathname: '/[store]',
                    query: {
                      store: store.slug,
                    },
                  },
                  `/${store.slug}`,
                  {
                    shallow: true,
                  }
                );
              }}
            />
          );
        }

        if (step === FLOW_STEPS.Verify) {
          return (
            <VerifyStep
              requestId={requestId}
              onNext={actions.verified}
              onPrev={actions.backFromVerify}
            />
          );
        }

        if (step === FLOW_STEPS.Waiting) {
          return <WaitingStep store={store} requestId={requestId} />;
        }
      })()}
    </div>
  );
}
