import React, {useCallback, useEffect, useState} from 'react';
import useAxios from '../../../hook/useAxios';
import styled from 'styled-components';
import {fetchSlots} from '../../../utils/api';
import {Button, ButtonGroup} from '../../Buttons';
import {DateList, DateSingle, SlotList, Slot} from '../../FormElements';
import {HeadingM, Subtitle, HeadingS, HeadingXS} from '../../Typography';
import Loading from '../../Loading';
import parse from 'date-fns/parse';
import {IconSlotNotFound} from '../../Icons';
import {Card, Center} from '../Boxes/layout';
import {useTranslation} from 'react-i18next';
import TextEditable from '../../TextEditable';

interface DateStepProps {
  store: Store;
  request: Partial<ReservationRequest>;
  onNext: (daytime: string | Date) => void;
  onPrev: () => void;
}

type SingleDateSlots = Record<string, boolean>;

export default function DateStep({
  store,
  request,
  onNext,
  onPrev,
}: DateStepProps) {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedSlot, setSelectedSlot] = useState<string | null>('');

  const {t} = useTranslation();

  const {
    loading,
    result: dates,
    error,
  } = useAxios(async () => {
    const slots = await fetchSlots(store.id, store.rooms[0].id, request.topic);

    const mappedDates: Record<string, SingleDateSlots> = {};

    for (let slot in slots) {
      const available = slots[slot] > 0;
      const [date, time] = slot.split(' ');

      mappedDates[date] = mappedDates[date] || {};

      const formattedTime = time.replace(/:00$/, '');
      mappedDates[date][formattedTime] = available;
    }

    return mappedDates;
  });

  useEffect(() => {
    setSelectedSlot(null);
  }, [selectedDate]);

  useEffect(() => {
    if (dates) {
      setSelectedDate(Object.keys(dates)[0]);
    }
  }, [dates]);

  const handleNext = useCallback(
    () =>
      onNext(
        parse(`${selectedDate} ${selectedSlot}`, 'yyyy-MM-dd HH:mm', new Date())
      ),
    [onNext, selectedDate, selectedSlot]
  );

  if (loading || error) {
    return <Loading />;
  }

  if (Object.keys(dates).length === 0) {
    return (
      <Card>
        <TextEditable
          roomId={store.rooms[0].id}
          langKey={'reservation-flow-title'}
        >
          <HeadingM>
            {t('reservation-flow-title', {ns: store.rooms[0].id})}
          </HeadingM>
        </TextEditable>
        <Center>
          <IconSlotNotFoundStyled />
          <HeadingXSStyled>
            Al momento non ci sono slot disponibili.
          </HeadingXSStyled>
        </Center>
        <ButtonGroup asFooter>
          <Button title="Indietro" secondary full onClick={onPrev} />
          <Button disabled title="Avanti" full />
        </ButtonGroup>
      </Card>
    );
  }

  return (
    <Card>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-title'}
      >
        <HeadingM>
          {t('reservation-flow-title', {ns: store.rooms[0].id})}
        </HeadingM>
      </TextEditable>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-calendar-step-text'}
      >
        <Subtitle>
          {t('reservation-flow-calendar-step-text', {ns: store.rooms[0].id})}
        </Subtitle>
      </TextEditable>

      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-calendar-step-title'}
      >
        <HeadingS>
          {t('reservation-flow-calendar-step-title', {ns: store.rooms[0].id})}
        </HeadingS>
      </TextEditable>
      <DateList>
        {Object.keys(dates).map((date, i) => {
          return (
            <DateSingle
              date={date}
              key={i + '-date'}
              selected={selectedDate === date}
              onClick={() => setSelectedDate(date)}
            />
          );
        })}
      </DateList>

      {selectedDate && (
        <SlotList>
          {Object.keys(dates[selectedDate]).map((slot, i) => {
            return (
              <Slot
                key={i + '-slot'}
                disabled={!dates[selectedDate][slot]}
                selected={selectedSlot === slot}
                onClick={() => setSelectedSlot(slot)}
              >
                {slot}
              </Slot>
            );
          })}
        </SlotList>
      )}

      <ButtonGroup asFooter>
        <Button title="Indietro" secondary full onClick={onPrev} />
        <Button
          disabled={!selectedSlot}
          title="Avanti"
          full
          onClick={handleNext}
        />
      </ButtonGroup>
    </Card>
  );
}

const IconSlotNotFoundStyled = styled(IconSlotNotFound).attrs((props) => ({
  fill: props.theme.colors.warning,
  size: '90px',
}))``;

const HeadingXSStyled = styled(HeadingXS).attrs((props) => ({
  $color: props.theme.colors.warning,
}))``;
