import React, {ReactElement, useContext} from 'react';
import styled, {css, ThemeContext} from 'styled-components';
import {Button} from '../../../Buttons';
import {Text} from '../../../Typography';
import {IconCalendar} from '../../../Icons';
import {IconHeadphones} from '../../../Icons';

export const BoxesAction = styled.div<{$visible?: boolean}>`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;

  @media ${(props) => props.theme.responsive.phone} {
    flex-wrap: wrap;
    position: static;
    top: inherit;
  }

  ${(props) =>
    props.$visible &&
    css`
      transition: all ease ${(props) => props.theme.timing.default};
      visibility: visible;
      opacity: 1;
      height: auto;
      transform: translateY(0);
      margin-top: ${(props) => props.theme.layout.frontendSpace};
      position: sticky;
      z-index: 3;
      top: calc(-55px + ${(props) => props.theme.layout.frontendSpace});

      @media ${(props) => props.theme.responsive.desktop} {
        top: calc(-20px + ${(props) => props.theme.layout.frontendSpace});
      }
    `}
`;

interface BoxActionProps {
  label: ReactElement<any>;
  buttonTitle: string;
  status?: string;
  onClick: () => void;
}

const BoxAction = ({label, buttonTitle, status, onClick}: BoxActionProps) => {
  const theme = useContext(ThemeContext);
  return (
    <Container>
      {status && (
        <Status>
          <StatusBar $status={status} />
          <StatusMessage $status={status}>
            {status === 'available' ? 'Disponibile' : 'Occupato'}
          </StatusMessage>
        </Status>
      )}
      {status ? (
        <IconHeadphones fill={theme.colors.main} />
      ) : (
        <IconCalendar fill={theme.colors.main} />
      )}
      {label}
      <Button full title={buttonTitle} onClick={onClick} />
    </Container>
  );
};

export default BoxAction;

const Container = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 50px ${(props) => props.theme.layout.frontendSpace} 24px;
  box-sizing: border-box;
  box-shadow: 0 10px 25px ${(props) => props.theme.colors.shadow};
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & + & {
    margin-left: ${(props) => props.theme.layout.frontendSpace};
  }

  svg {
    margin-bottom: 12px;
  }

  @media ${(props) => props.theme.responsive.phone} {
    width: 100%;
    padding-top: 40px;
    padding-bottom: ${(props) => props.theme.layout.frontendSpace};

    > svg {
      width: 55px;
      height: 55px;
    }

    & + & {
      margin-top: ${(props) => props.theme.layout.frontendSpace};
      margin-left: 0;
    }
  }

  img {
    display: block;
    margin: 0 auto 16px;
  }

  h3 {
    padding: 0 12px;
  }
`;

const Status = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 16px;
  box-sizing: border-box;
`;

const StatusMessage = styled(Text)<{$status: string}>`
  display: block;
  margin: 0;
  color: ${(props) =>
    props.$status === 'available'
      ? props.theme.colors.success
      : props.theme.colors.warning};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
`;

const StatusBar = styled.div<{$status: string}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${(props) =>
    props.$status === 'available'
      ? props.theme.colors.success
      : props.theme.colors.warning};
`;
