import {useCallback, useState} from 'react';
import {fetchStoreTopics} from '../utils/api';
import {sendCustomEvent} from '../utils/analytics';

export enum FLOW_STEPS {
  Argument = 'Argument',
  Calendar = 'Calendar',
  Customer = 'Customer',
  Confirm = 'Confirm',
}

export default function useReservationFlow(store: Store) {
  const [step, setStep] = useState<FLOW_STEPS>(FLOW_STEPS.Argument);
  const [data, setData] = useState<Partial<ReservationRequest>>({
    room: store.rooms[0].id,
  });
  const [topics, setTopics] = useState<ReservationTopic[]>([]);

  const updateData = useCallback((r: Partial<ReservationRequest>) => {
    setData((data) => ({...data, ...r}));
  }, []);

  const start = async () => {
    const topics = await fetchStoreTopics(store.id);
    setTopics(topics);

    if (topics.length === 0) {
      await setTopic();
      return;
    }

    if (topics.length === 1) {
      await setTopic(topics[0].id);
      return;
    }

    return setStep(FLOW_STEPS.Argument);
  };

  const setTopic = async (topicId?: string) => {
    await updateData({topic: topicId});

    sendCustomEvent('flowInteraction', 'setTopic', 'reservation');

    setStep(FLOW_STEPS.Calendar);
  };

  const setCalendar = async (daytime: string | Date) => {
    await updateData({daytime: daytime});

    sendCustomEvent('flowInteraction', 'setCalendar', 'reservation');

    setStep(FLOW_STEPS.Customer);
  };

  const setCustomer = async (data: ReservationRequest) => {
    await updateData(data);

    sendCustomEvent('flowInteraction', 'confirm', 'reservation');

    setStep(FLOW_STEPS.Confirm);
  };

  const backFromCalendar = async () => {
    return setStep(FLOW_STEPS.Argument);
  };

  const backFromCustomer = async () => {
    return setStep(FLOW_STEPS.Calendar);
  };

  return {
    step,
    request: data,
    topics,
    start,
    setTopic,
    setCalendar,
    setCustomer,
    backFromCalendar,
    backFromCustomer,
  };
}
