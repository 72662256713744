import {useCallback, useState} from 'react';
import {sendJoinRequest} from '../utils/api';
import {sendCustomEvent} from '../utils/analytics';

export enum FLOW_STEPS {
  Customer = 'Customer',
  Verify = 'Verify',
  Waiting = 'Waiting',
}

export default function useLiveRequest(store: Store) {
  const [step, setStep] = useState<FLOW_STEPS>(FLOW_STEPS.Customer);
  const [data, setData] = useState<Partial<LiveRequest>>({
    store: store.id,
    room: store.rooms[0].id,
  });

  const [requestId, setRequestId] = useState<string>(null);

  const updateData = useCallback((r: Partial<LiveRequest>) => {
    setData((data) => ({...data, ...r}));
  }, []);

  const setName = async (name: string) => {
    await updateData({
      customerName: name,
    });

    const request = await sendJoinRequest({
      ...data,
      customerName: name,
    } as LiveRequest);

    sendCustomEvent('flowInteraction', 'sendRequest', 'live/directAccess');

    setRequestId(request.id);

    if (request.status !== 'pending-verification') {
      return setStep(FLOW_STEPS.Waiting);
    }

    setStep(FLOW_STEPS.Verify);
  };

  const backFromVerify = async () => {
    return setStep(FLOW_STEPS.Customer);
  };

  const verified = async () => {
    // TODO: checkJoinRequest instead send another
    const request = await sendJoinRequest(data as LiveRequest);

    setRequestId(request.id);

    if (request.status === 'pending-verification') {
      setStep(FLOW_STEPS.Verify);
      return;
    }

    setStep(FLOW_STEPS.Waiting);
  };

  return {
    step,
    request: data,
    requestId,
    setName,
    backFromVerify,
    verified,
  };
}
