import React, {useState, useEffect} from 'react';
import {Button, ButtonGroup} from '../../Buttons';
import {Choice, ChoiceBlock} from '../../FormElements';
import {HeadingM, Subtitle, HeadingS} from '../../Typography';
import {Card} from '../Boxes/layout';
import {useTranslation} from 'react-i18next';
import TextEditable from '../../TextEditable';

interface ArgumentStepProps {
  topics: ReservationTopic[];
  store: Store;
  onPrev: () => void;
  onNext: (topic: string) => void | Promise<void>;
}

export default function ArgumentStep({
  topics,
  store,
  onPrev,
  onNext,
}: ArgumentStepProps) {
  const [selectedTopic, setSelectedTopic] = useState<string>('');

  const {t} = useTranslation();

  useEffect(() => {
    if (topics.length === 0) {
      onNext('');
    }

    if (topics.length === 1) {
      onNext(topics[0].id);
    }
  }, [topics, onNext]);

  return (
    <Card>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-title'}
      >
        <HeadingM>
          {t('reservation-flow-title', {ns: store.rooms[0].id})}
        </HeadingM>
      </TextEditable>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-argument-step-text'}
      >
        <Subtitle>
          {t('reservation-flow-argument-step-text', {ns: store.rooms[0].id})}
        </Subtitle>
      </TextEditable>
      <TextEditable
        roomId={store.rooms[0].id}
        langKey={'reservation-flow-argument-step-title'}
      >
        <HeadingS>
          {t('reservation-flow-argument-step-title', {ns: store.rooms[0].id})}
        </HeadingS>
      </TextEditable>
      <ChoiceBlock>
        {topics
          .sort((a, b) => (a.ordering < b.ordering ? -1 : 1))
          .map((topic) => (
            <Choice
              small
              key={topic.id}
              selected={topic.id === selectedTopic}
              onClick={() => setSelectedTopic(topic.id)}
            >
              {topic.title}
            </Choice>
          ))}
      </ChoiceBlock>

      <ButtonGroup asFooter>
        <Button title="Indietro" secondary full onClick={onPrev} />
        <Button
          disabled={!selectedTopic}
          title="Avanti"
          full
          onClick={() => onNext(selectedTopic)}
        />
      </ButtonGroup>
    </Card>
  );
}
