import React from 'react';
import EndStep from './EndStep';

interface EndFlowProps {
  store: Store;
}

export default function EndFlow(props: EndFlowProps) {
  return <EndStep store={props.store} />;
}
