import React from 'react';
import styled from 'styled-components';
import {HeadingM, Text, Label} from '../../../Typography';
import {Input} from '../../../FormElements/';
import {ButtonGroup, Button} from '../../../Buttons';

const BoxUserInfo = () => {
  return (
    <>
      <HeadingM>Parla con un consulente</HeadingM>
      <Subtitle>
        Comincia da qui, puoi iniziare una nuova conversazione se l’operatore è
        disponibile oppure prenotare il tuo appuntamento
      </Subtitle>
      <Label>Inserisci il tuo nome</Label>
      <Input />
      <Footer>
        <ButtonGroup>
          <Button title="Indietro" secondary full />
          <Button title="Avanti" full />
        </ButtonGroup>
      </Footer>
    </>
  );
};

export default BoxUserInfo;

const Subtitle = styled(Text)`
  margin-bottom: 30px;
`;

const Footer = styled.div`
  margin-top: 50px;
`;
