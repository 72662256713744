import React from 'react';
import styled from 'styled-components';
import {useRouter} from 'next/router';
import {useUser} from '../../../contexts/UserContext';
import {Button, ButtonGroup} from '../../Buttons';
import {HeadingM, HeadingXS, Text} from '../../Typography';
import {Card, Center} from '../Boxes/layout';
import {IconEndRoom} from '../../Icons';

interface EndWebinarStepProps {
  store: Store;
}

export default function EndWebinarStep({store}: EndWebinarStepProps) {
  const router = useRouter();
  const {user} = useUser();
  const goToHome = () => {
    router.push(
      {
        pathname: '/[store]/',
        query: {
          store: store.slug,
        },
      },
      `/${store.slug}`,
      {
        shallow: true,
      }
    );
  };
  const goToAdmin = () => {
    router.push('/admin');
  };

  return (
    <Card>
      <HeadingM>Il Webinar è terminato</HeadingM>

      <Center>
        <IconEndRoomStyled />
        <HeadingXSStyled>Grazie per aver partecipato.</HeadingXSStyled>
        <Text>Puoi abbandonare la pagina.</Text>
      </Center>

      <ButtonGroup asFooter>
        {user && !user.isGuest && (
          <Button
            onClick={() => goToAdmin()}
            title="Pannello Admin"
            secondary
            full
          />
        )}
        <Button title="Torna alla home" full onClick={() => goToHome()} />
      </ButtonGroup>
    </Card>
  );
}

const IconEndRoomStyled = styled(IconEndRoom).attrs((props) => ({
  fill: props.theme.colors.success,
  size: '90px',
}))``;

const HeadingXSStyled = styled(HeadingXS)`
  color: ${(props) => props.theme.colors.success};
`;
