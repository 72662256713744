import React from 'react';
import styled from 'styled-components';
import {useRouter} from 'next/router';
import {HeadingM, HeadingS, HeadingXS, Text, TextS} from '../../Typography';
import {IconCalendar, IconConfirmAppointment} from '../../Icons';
import {format} from 'date-fns';
import {it} from 'date-fns/locale';
import {Button} from '../../Buttons';
import {Card} from '../Boxes/layout';

interface ConfirmStepProps {
  store: Store;
  request: Partial<ReservationRequest>;
}

export default function ConfirmStep({store, request}: ConfirmStepProps) {
  const date = new Date(request.daytime ? request.daytime : '');
  const router = useRouter();

  const backToHome = () => {
    router.push(`/${store.slug}`);
  };

  return (
    <Card>
      <HeadingM>Prenota un appuntamento</HeadingM>

      <Confirm>
        <IconConfirmAppointmentStyled />
        <Title>Appuntamento confermato!</Title>
        <Text>
          Riceverai a breve una mail di conferma con il link per partecipare
          all’appuntamento.
        </Text>
        <TextS>
          Nel frattempo, puoi{' '}
          <a
            href="/prova-dispositivo"
            target="_blank"
            rel="noopener noreferrer"
          >
            provare il tuo dispositivo
          </a>{' '}
          per ottenere il massimo dal tuo appuntamento.
        </TextS>
      </Confirm>

      {request && (
        <AppointmentPreviewCard>
          <AppointmentPreviewCardLeft>
            <HeadingXS>
              {format(date, 'EEEE d MMMM yyyy', {locale: it})}
            </HeadingXS>
            <Text>{format(date, 'HH:mm')}</Text>
          </AppointmentPreviewCardLeft>
          <AppointmentPreviewCardRight>
            <IconCalendar fill="#fff" size="44px" />
          </AppointmentPreviewCardRight>
        </AppointmentPreviewCard>
      )}

      <Button
        onClick={() => backToHome()}
        title="Torna alla home"
        secondary
        full
      />
    </Card>
  );
}

const Confirm = styled.div`
  text-align: center;
  padding-top: 30px;
`;

const Title = styled(HeadingS)`
  color: ${(props) => props.theme.colors.success};
`;

const AppointmentPreviewCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.success};
  padding: 24px;
  margin-bottom: 24px;
  color: #fff;
  margin-top: calc(${(props) => props.theme.layout.frontendSpace} * 3);
`;

const AppointmentPreviewCardLeft = styled.div`
  flex: 0 0 80%;

  ${Text} {
    color: #fff;
    margin: 0;
  }
  ${HeadingXS} {
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
`;
const AppointmentPreviewCardRight = styled.div`
  flex: 0 0 20%;
  text-align: right;
`;

const IconConfirmAppointmentStyled = styled(IconConfirmAppointment).attrs(
  (props) => ({
    fill: props.theme.colors.success,
    size: '100px',
  })
)``;
