import React, {PropsWithChildren, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import FlashMessage from '../../FlashMessages';

type FormElementsProps = PropsWithChildren<{
  invisible?: boolean;
  submitError?: any;
}>;

// TODO: remove invisibile props and use two form elements types
const FormElements = ({
  invisible = false,
  submitError,
  children,
}: FormElementsProps) => {
  const errorContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!submitError) {
      return;
    }

    if (!errorContainerRef.current) {
      return;
    }

    errorContainerRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [submitError]);

  return (
    <Container $invisible={invisible}>
      {children}
      {submitError && (
        <FlashMessage
          status="error"
          title={submitError}
          ref={errorContainerRef}
        />
      )}
    </Container>
  );
};

export default FormElements;

export const Container = styled.div<{$invisible: boolean}>`
  display: block;
  border-radius: 5px;
  padding: 0;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.background};

  ${(props) =>
    props.$invisible &&
    css`
      padding: 0;
      margin-bottom: 0;
      background-color: transparent;
    `}

  @media ${(props) => props.theme.responsive.phone} {
    padding: ${(props) => props.theme.layout.frontendSpace};
  }
`;

export const ErrorContainer = styled.div`
  display: block;
  padding: 12px 0;
  margin-top: 12px;
  font-size: 14px;
  font-family: ${(props) => props.theme.typography.main};
  color: ${(props) => props.theme.colors.error};
`;
